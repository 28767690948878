<template>
  <van-tabbar
  class="footer"
    v-if="show"
    v-model="active"
    active-color="#ff4ea3"
    :border="true"
    inactive-color="#fff"
  >
    <van-tabbar-item to="/Home" @click="setActive(0)">
      <span>{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/home.png' : '/img/footer/home1.png'"
          :alt="$t('foorter.index')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? '/img/footer/home.png' : '/img/footer/home1.png'"
          :alt="$t('foorter.index')"
          :class="$t('foorter.index')"
          style="height: 4rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    
    <van-tabbar-item to="/Game" @click="setActive(1)">
      <span>{{ $t("foorter.subscribe") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/game.png' : '/img/footer/game1.png'"
          :alt="$t('foorter.subscribe')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? '/img/footer/game.png' : '/img/footer/game1.png'"
          :alt="$t('foorter.subscribe')"
          :class="$t('foorter.subscribe')"
          style="height: 4rem"
          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
    
    <van-tabbar-item to="/pickgirl" @click="setActive(2)">
      <span>{{ $t("foorter.pickgirl") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/pickgirl.png' : '/img/footer/pickgirl1.png'"
          :alt="$t('foorter.pickgirl')"
          v-show="show !== 2"
        />
        <img
          :src="props.active ? '/img/footer/pickgirl.png' : '/img/footer/pickgirl1.png'"
          :alt="$t('foorter.pickgirl')"
          :class="$t('foorter.pickgirl')"
          style="height: 4rem"
          v-show="show === 2"
        />
      </template>
    </van-tabbar-item>
    
    <van-tabbar-item to="/Mine" @click="setActive(3)">
      <span>{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/mine.png' : '/img/footer/mine1.png'"
          :alt="$t('foorter.my')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? '/img/footer/mine.png' : '/img/footer/mine1.png'"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
          style="height: 4rem"
          v-show="show === 3"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>

export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {
    setActive(index) {
      this.active = index;
      this.show = true;
    }
  },
  watch: {
    $route(to) {
      const routes = {
        home: 0,
        game: 1,
        pickgirl: 2,
        mine: 3
      };
      if (routes[to.name] !== undefined) {
        this.setActive(routes[to.name]);
      } else {
        this.show = false;
      }
    },
  },
  created() {
    const routes = {
      home: 0,
      game: 1,
      pickgirl: 2,
      mine: 3
    };
    if (routes[this.$route.name] !== undefined) {
      this.setActive(routes[this.$route.name]);
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 50px;
.van-tabbar {
  height: @tabbar-height;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
}
.footer {
  background: linear-gradient(180deg,#402f77,#332862);
}
.tui {
  width: 4rem;
  height: 4rem!important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}

.van-tabbar-item--active {
  background: none !important;
}

</style>
