<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('防骗指南')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
      <img class="imgfang" src="../../assets/img/fangpian.jpg" alt="" />
    </div>
  </div>

</template>
<script>
export default {
	data() {
		return {
			
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'Game' });
		},

	},

};
</script>

<style>
.imgfang {
  width: 100%;
}
</style>