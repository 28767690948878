import axios from 'axios'
import api from './api'
import Vue from 'vue';
let Base64 = require('js-base64').Base64;
import { Toast } from 'vant';
import qs from "qs";

Vue.use(Toast);

// 创建axios实例对象
const instance = axios.create({
    baseURL: 'https://www.dailydealsmall.com/api', // 服务器地址
    //  baseURL: 'http://www.gwcp.com/api', // 服务器地址
    timeout: 5000, // 默认超时时长
})

// 请求拦截器
instance.interceptors.request.use(config => {
    // 检查是否包含文件上传的情况
    if (config.data instanceof FormData) {
        // 如果是 FormData 类型，设置 multipart/form-data
        config.headers['Content-Type'] = 'multipart/form-data';
    } else if (config.method === "post") {
        // 其他 POST 请求
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
        config.data = qs.stringify(config.data); // 转换为 URL 编码格式
    }

    // 添加 token 和 lang 头部
    if (localStorage.getItem('token')) {
        config.headers['token'] = Base64.encode(localStorage.getItem('token'));
    }
    config.headers['lang'] = 'jp_jp';
    var yvyan = localStorage.getItem('lang');
    if (yvyan) {
        config.headers['lang2'] = yvyan;
    } else {
        config.headers['lang2'] = 'jp_jp';
    }
    
    return config;
}, err => {
    console.error('请求失败', err);
});

// 响应拦截器
instance.interceptors.response.use(res => {
    if (res.data.msg === "鉴权错误") {
        localStorage.clear();
        this.$router.push({ path: '/Login' });
    }
    return res; // 该返回对象会传到请求方法的响应对象中
}, err => {
    // 响应错误处理
    return Promise.reject(err);
});

// 封装axios请求方法，参数为配置对象
async function http(option = {}) {
    let result = null;
    if (option.method === 'get' || option.method === 'delete') {
        // 处理get、delete请求
        await instance[option.method](
            api[option.url],
            { params: option.data }
        ).then(res => {
            result = res.data;
            result.data = JSON.parse(Base64.decode(result.data));
        }).catch(err => {
            result = err;
        });
    } else if (option.method === 'post' || option.method === 'put') {
        // 处理post、put请求
        await instance[option.method](
            api[option.url],
            option.data // 直接传递 data，这里可以是 FormData 或普通对象
        ).then(res => {
            result = res.data;
            result.data = JSON.parse(Base64.decode(result.data));
        }).catch(err => {
            result = err;
        });
    }
    return result;
}

export default http;
