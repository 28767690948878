<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" :title="$t('game.hall')" />
    <div class="convention-item">
      <ul data-v-08c28d8f="" class="girl-ul">
        <li data-v-08c28d8f="" @click="$router.push('/Video')">
          <p data-v-08c28d8f="" class="girl-title"><img data-v-08c28d8f=""
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAQCAYAAAD52jQlAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJYSURBVHgBjVPNahNRFP7OzA1pVWqjxCgKidi9+goVQRCk3cTiyr0L20foE9SnaEAErUgXXVRxI4hKNi4lZKOLVNQabUlm5njuuT8zFZRe5s79mfPzne98Q+O7u1dznrwEcwOQN5hkBYF0n+gKvZMzqmf7LkCPGo9vraEyaNTdGRBTW1woBILuEPc2XHRg4kJtQ2JFsth8cvNVsDE5am11Ul+yXvohhpYoctAUmsBjVkO2dmrZriI1UyRkH+s0KvZpY++5Oq6dvYOmOU0BpLqS92IHM6IXUuwYdF8vIcn7SUY1niLFlAy2f/YxnI4wnIywPe7D3afIyJBMZGw4g2G9Y7+SXZNrn7pvdpnSp0D9g5kgjRkXZjrYGb/X/cXaBQlqQj9clxzVDmTJtSVsVa8EsRTcoI8r7xyh7Dy/5vt8kB/iUr1VlucZ1YCBA/YkIGQJR+XUuC6Sk9KcOUNzAjCzzVH9aE6yXQ821b09urPLZ+2NcBR6H7VJ/mQNYpXklRGaFABXm+YFYqRBmpK57K+WK+kVQXRip6dICiMidACDnQRlo/VQQpr1sDiw02vUl18ZM8mszBnHH1V/GESKzTRJKdTf//UWz/Z6pfz+Me63HqAzuwCfTwIW6xJjXpA9lFV0CtUaBDGfMk3UkxP437DfT9aasHpV7eqsD270rqz++I3GYu/ydXpx7/M3oXI+MA5P0NE/vmxi9SaqCbR0e/P8VvgijUrWRbQbZTddC6uCr0TxNlH7Ys7D5V5rCziaDr2VLx1jTAfHGGluOE8z9TMFf1/ePNf/2+YP2Mgvw8s6TU8AAAAASUVORK5CYII="
              alt="">
            <span data-v-08c28d8f="">{{ $t('game.vip_video') }}</span>
          </p>
          <van-icon name="arrow" />
        </li>
        <li @click="info">
          <p data-v-08c28d8f="" class="girl-title">
            <img data-v-641a2818=""
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMQSURBVHgBzVW9bhNBEJ7ZOzuRkAhpEB0XIYo0JBY0VDgNEqKxJSCnECnkCSBPEPMEwAtAAiKxlMJQ0XHuqCLZokiF4lSIKs6PlJ/7GWbn9s72ObaMRJG173b3bnbm22++2QO4TO3ErTknpZrzL2vUqIZHT2pFiJSHOev78dPa7KjrcNjLfUYMYb5oAy4BREXEjjkR1glhLSRqTm49bowUYN/9xijxg5k6fUbEPw7CHSLpGHqGpOf83wXFfaTKk1sP04B2d4AgnjpwUSN2idQBlO7GPEOY4sgaxLXuZT0BTnlqg5jpBfFC0tPYuYwSx9QJHdtS/MzuZT2zA43HEgukeCGZYEKJQatjpWPEAx632cSJIYU9AVRvAJsvSy6fx74eo2We2RAqW3qfQQQo/bvgLFcIKNf2ZY0lLAzcAcA4OwhTSiSBJDAw5hqTjLc4m8vHp+ONK2O+x+9mYtIIw4zyVTYHGhkjxgBypJH6qDAgS4/BJ8WX1QghP3e7er+eHwu9ANSMr+3NroIM5gxFINsUZ0odBBGUQ7TXhBJNEdr18zNrbrpaaDXnm2/Y6ax2zs8poQiGUaSdKJECyzqClqWgUagWvmy7zT3mzLlXvfNC2227P1eZyJek8RmRotHVOcCwALwl3qbmnVfMMlXeD3dn7m51upLYeO6O40NU0XI1dkahkjOIhqmI47WYY1GOpikE+2aElue5u05qgcoLjML4PWl736iMLwrDsXa3x76zqL7waz+pRozLTHdtllOZt+fwafDeABeFoUqkJuVCDzZvqYEUCU1k1dmylNZzfJtgkbIcoR2moFAqTriBtFuHYTnQzSdaQbRLYIrYVKzUBE8mkgKnzhmE5hQhivzXWX9934NH1alWCNYr0bXhOc6JkpoQ3kXzca5kztyxVFf02qy/gd+Drwt/ViPAigHJiCPsZkeyIyesaHqlvHnj7UV+hn5wau5vx1d5j/lxIOYLkhNVJ5bvDQ68/Gzj+mgfnEFt4/nhEqOssGfHnKp7FqrK/Oer6/A/28fFw9KnxaMiXKb2F/WdadGC1sP4AAAAAElFTkSuQmCC"
              style="height: 19px; width: 18px;"><span>{{ $t('game.near_people') }}</span>
          </p>

          <van-icon name="arrow" />
        </li>
        <!-- <li @click="$router.push('/FangPian')">

          <p data-v-08c28d8f="" class="girl-title">
            <img data-v-641a2818=""
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOLSURBVHgBhVVtaxNZFD7nzrSmXcXJgrIvH5z4rbDQBJYFWdhNuu6yskjTZbubloXGX9D6C9r+gcX9BbUgMVqxKaJCQRPxgx8TQfCbmfpWECFTRR1n7tzjuTOZ0NpEh5AZzstzn+e5584gfOJ6/de1vAE4KQTZisBSCu4TQu3QldONQT3YL9iZvp4VgCsIlEVdggRESQMSg26FPhbStVPOZwE7s5tZkFAHUBYQI2lExkuKuw/6sRMQThxd+601EHC7VLcNCusMY8etulPVAETNQLACUjkEMZfUc4XzxjdymVrBTWLmHnoEi5KEHa+CjmHC1JHKyT0MXkxvngvRrPNSh5m4nRqGeQ4v9wWUIPKJLr4tfV35ufXkn7vcoBZ4U1xUuHR07aeNp3/XGcD4j+KVF3YD9iS3WS7QUBtj31z70o9f7o7xT3sJrh+kLd14YPgRM0xHOSGOZ6onHI0jevTCLyzJhAMUGIDY0qEAUnkJRhQLySSJBhxKpYraMx/Ejs5xDCVIe59kzwhd5CZeFXlkooKQTUfkEj0ziJEeAtloFptWiIEdDYDOeQedBKfHcIwpS2HsaBYBGIcfzDTnONZQhAvMvCVRbPmAZ3TdgVGYCzU74pkAwxmr5Zx9HuqrWWou8Vgsdkem44dq4oe1XOujmjzn6tjdPd6o8vjl8dX+gFrKyFCddWTjJBsOuKqA7sTVNMl2FLVMjPW3vr/0XW43xr6Tcq/00Ga7GBSORRxio7rV2POTZ6GtACZOVMecTwImoKEwmqDIikCAumwp2hlm6JigCh+D6Uv0A/S8lEvCzIc41InMh2ijUI8Vb5orB4DtYbhebFsHR8W8ICozk42TFzMLt0rtMghcoUQyRIoLv1YzjVsz7XXmfQzR2BBKrhaqGafHcHP2+fzIiNFkT/DVO8ppMB3/pZo57ytcDsEkPr/6aJ7VYFHuYmYqIPpTkrJ8Yd6+MfO4GDG8Wd62lU+PGPvsH5Wv/u8n4+bss0X20P298k3f/PXS9iIImvffvj9ueuC5CKM7bH4WBlynKt8uD8qtlztW4PtZjAcz/rs6+3KS3TmnndL+kcKa6ZmtqVraHQQiPZnlbv48YJmNdYeFWTx9IX1/z9hc+fdlkY8aeyHG2fxxHpIdXsHlRocLLV5Qv2gsjO7EMWogqtXpC0caCQYOkrJS7FjDo2Dzh8kyd701PQkOeOCeGcD+A5vSpad4AlkxAAAAAElFTkSuQmCC"
                style="height: 21px; width: 18px;"><span data-v-08c28d8f="">{{ $t('game.fraudPreventionGuide') }}</span>
            
            </p>

          <van-icon name="arrow" />
        </li> -->
       

      </ul>

      <div class="gameContent">

        <div style="display:flex;justify-content: space-between;align-items: center">
          <img src="../../assets/sfks-2.png" alt="" style="border-radius: 10px;width: 49%" @click="toLottery(gameitem[0].key, gameitem[0].id)">
          <img src="../../assets/sfks2-2.png" alt="" style="border-radius: 10px;width: 49%" @click="toLottery(gameitem[1].key, gameitem[1].id)">
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  data() {
    return {
      gameitem: [{}, {}, {}, {}],
      lotteryitem: [{}, {}, {}, {}],
      isLoading: false,
      activeKey: 0,
    };
  },
  methods: {
    info() {
      this.$toast({
        message: this.$t('game.客服'),
        icon: 'like-o',
      });
    },
    onRefresh() {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoading = false;
      }, 500);
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })

      }
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data: { class: index },
        url: 'lottery_list'
      }).then(res => {
        console.log(res.data, 'this.gameitem')
        this.gameitem = res.data;
      })
    },
    getLotteryItem() {
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res => {
        this.lotteryitem = res.data;
      })
    }
  },
  created() {
    this.getGameItem();//获取首页游戏列表
    this.getLotteryItem();
  }
};
</script>

<style lang='less' scoped>
.gameContent {
  background: #29243d;
  padding: 20px;
}

.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
  color: #ddb285;
}



.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #fff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #ddb285;
  border-radius: 5px;
  width: 10px;
}

::v-deep .van-sidebar-item--select {
  color: #ddb285;
  font-size: 35px;
  text-align: center;

}

.van-sidebar-item {
  font-size: 35px;
  text-align: center;
  padding: 50px 0;
  background-color: #29243d;
  color: #ddb285;
}

.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background-color: #29243d;
}

.convention-item {
  height: 100%;
  background: #29243d;
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}

.convention-item .left {
  height: 100%;
  background-color: #29243d;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right {
  height: 100%;
  flex: 1;
  background-color: #29243d;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right .list-wrapper {
  padding: 20px 20px;
  min-height: 800px;
}

.convention-item .right .list-wrapper .game_item_img {
  width: 200px;
  height: 200px;
}

.convention-item .right .list-wrapper span {
  margin-top: 10px;
  font-size: 30px;
  color: #ddb285;
}

.convention-item .right .list-wrapper span:last-child {
  margin-top: 10px;
  font-size: 24px;
  color: #ddb285;
}

.van-grid-item {
  padding: 10px;

}

::v-deep .van-grid-item__content--center {
  border-radius: 15px;
}

::v-deep .van-image__img {
  border-radius: 40px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-grid-item__content {
  background-color: #29243d;
}

::v-deep [class*=van-hairline]::after {
  border: none;
}

.girl-ul li {
  font-size: 28px;
  color: #fff;
  padding: 10px 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.girl-ul li div {
  width: 76%;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
}

.girl-ul li .girl-title {
  width: 30%;
  padding-left: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.girl-ul li .girl-title img {
  height: 1rem;
  width: 1.1rem;
  // margin-top: 7.5%;
  // margin-right: 3%;
  vertical-align: middle;

}

.cube-scroll-list-wrapper span {
  color: #fff;
}

.fuli-lot .girl-ul li .girl-cont {
  width: 76%;
  overflow: hidden;
}


.girl-ul li .girl-cont .data-box {
  float: left;
  text-align: right;
  position: relative;
}

.girl-ul li .girl-cont .data-box span {
  display: inline-block;
  color: #666666;
  margin-right: 40px;
}

.girl-ul li .girl-cont .data-box img {
  width: 80px;
  vertical-align: middle;
}

.girl-ul li .girl-cont .data-box .border {
  position: absolute;
  top: 0;
  right: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
}
.girl-ul{
  color: #fff;
    border-radius: .42667rem;
    width: 94%;
    margin-left: 3%;
    margin-top: 1%;
    margin-bottom: 5%;
    background: #4a4558;
}

::v-deep .van-icon {
  font-size: 36px;
  color: #666;
  margin-right: 10px;
}
</style>
